<template>
  <div :style="'height:' + height + 'px'" class="a-detail-card">
    <img :src="image" :height="imgHeight" />
    <span>{{ text1 }}</span>
    <br />
    <span>{{ text2 }}</span>
  </div>
</template>

<script>
export default {
  name: "DetailCard",
  props: {
    height: Number,
    image: String,
    text1: String,
    text2: String,
  },
  computed: {
    imgHeight() {
      return this.height - 100;
    },
  },
};
</script>

<style scoped>
.a-detail-card {
  background: #f4f3f8;
  max-width: 250px;
  min-width: 250px;
  border-radius: 0 0 50px 50px;
  text-align: center;
  margin: 0 24px;
  position: relative;
}
.a-detail-card img {
  object-fit: cover;
  width: 250px;
}
.a-detail-card h4 {
  color: #333333;
  font-family: "Quicksand", sans-serif !important;
  font-size: 21px;
}
</style>
