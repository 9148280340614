<template>
  <div v-if="isOverlayShowing" id="a-overlay">
    <h4>Buena suerte</h4>
    <img :src="require('@/assets/icons/smile.svg')" />
    <h5>{{ user.first_name }} {{ user.last_name }}</h5>
  </div>
  <div id="a-container">
    <Carousel
      :isSliderActive="false"
      :customTitlePt1="'Bienvenido a'"
      :customTitlePt2="selectedTest.name"
    >
      <div id="a-carousel-inactive-content">
        <div id="a-row">
          <div id="a-carousel-text">
            <h2>¡Ey! {{ user.first_name }} {{ user.last_name }}</h2>
            <p>
              {{ selectedTest.description }}
            </p>
          </div>
          <div id="a-carousel-detail">
            <DetailCard
              :height="400"
              :text1="'Tiempo máx.'"
              :text2="`${selectedTest.time} min`"
              :image="require('@/assets/icons/clock_card.svg')"
            />
            <DetailCard
              :height="462"
              :text1="`${selectedTest.questions} preguntas`"
              :text2="'para resolver'"
              :image="require('@/assets/icons/questions_card.svg')"
            />
            <DetailCard
              :height="400"
              :text1="'Cuentas con máx.'"
              :text2="`${selectedTest.attempts} intentos`"
              :image="require('@/assets/icons/goal_card.svg')"
            />
          </div>
        </div>

        <div id="a-carousel-btn">
          <primary-button
            :title="'Comenzar'"
            :isValid="true"
            :isLoading="isLoading"
            @onClick="onBeginClick"
          />
          <Button
            label="Regresar"
            class="p-button-danger"
            :loading="isLoading"
            @click="onCancel"
          />
        </div>
      </div>
    </Carousel>
  </div>
  <Toast />
</template>

<script>
import Carousel from "@/components/carousel/Carousel.vue";
import DetailCard from "@/components/test/DetailCard.vue";
import PrimaryButton from "../components/widgets/PrimaryButton.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "InitialTestPage",
  components: {
    Carousel,
    DetailCard,
    PrimaryButton,
  },
  data() {
    return {
      isOverlayShowing: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("test", ["selectedTest"]),
  },
  methods: {
    ...mapMutations("answers", ["setAnswerAttempt"]),
    onBeginClick() {
      this.isOverlayShowing = true;
      this.isLoading = true;

      this.checkAttempts()
        .then(() => {
          this.$store
            .dispatch("test/GET_QUESTION_LIST", this.selectedTest)
            .then(() => {
              this.$store
                .dispatch("answers/SAVE_ANSWER_ATTEMPT", {
                  user: this.user.pk,
                  test: this.selectedTest.id,
                })
                .then((response) => {
                  this.setAnswerAttempt(response);
                  console.log(response);
                  setTimeout(() => {
                    this.isLoading = false;
                    this.$router.push({ name: "VideoTestPage" });
                  }, 1000);
                });
            })
            .catch((error) => {
              if (error == "token") {
                this.onBeginClick();
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Ha ocurrido un error",
                  detail: error,
                  life: 3000,
                });
              }
            });
        })
        .catch(() => {
          this.isOverlayShowing = false;
          this.isLoading = false;
          this.$toast.add({
            severity: "error",
            summary: "Ha ocurrido un error",
            detail: "Ya ha alcanzado el límite de intentos para esta prueba.",
            life: 3000,
          });
        });
    },
    checkAttempts() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch("answers/GET_ANSWER_ATTEMPT").then((response) => {
          if (response.length < this.selectedTest.attempts) {
            resolve();
          } else {
            reject();
          }
        });
      });
    },

    onCancel() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style scoped>
#a-overlay {
  width: 100%;
  height: 100%;
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  position: fixed;
  background: rgba(3, 13, 33, 0.95);
}
#a-overlay h4 {
  font-size: 40px;
  color: white;
  font-family: "Photoshoot", sans-serif !important;
}
#a-overlay h5 {
  font-size: 40px;
  color: var(--color-3);
  font-family: "Quicksand", sans-serif !important;
  font-size: 32px;
}
</style>

<style>
#a-container {
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

#a-carousel-inactive-content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  height: 100%;
}
#a-carousel-inactive-content #a-row {
  display: flex;
  flex-flow: nowrap row;
  justify-content: space-between;
}

#a-carousel-inactive-content #a-carousel-text {
  max-width: 470px;
  margin: 0 0 0 50px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
#a-carousel-inactive-content #a-carousel-text h2 {
  color: var(--color-1);
  font-family: "Quicksand", sans-serif !important;
  font-size: 25px;
}
#a-carousel-inactive-content #a-carousel-text p {
  font-size: 18px;
}

#a-carousel-detail {
  display: flex;
}

#a-carousel-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
#a-carousel-btn button {
  max-width: 290px;
  margin: 20px 20px 70px 20px;
}
</style>
